import request from '@/libs/request';

// 获取医生列表
export const SearchKolHcpList = data => request('MdmAdmin/KolService/SearchKolHcpList', data);
// 详情页基础属性查询
export const SearchHcpBaseDetail = data => request('MdmAdmin/KolService/SearchHcpBaseDetail', data);
// 关系网络查询
export const SearchHcpSocialRelations = data =>
  request('MdmAdmin/KolService/SearchHcpSocialRelations', data);
// 影响力因子查询
export const SearchHcpInfluence = data => request('MdmAdmin/KolService/SearchHcpInfluence', data);
// 发表文章数据查询
export const SearchHcpArticle = data => request('MdmAdmin/KolService/SearchHcpArticle', data);
// Hcp发表文章年份统计
export const SearchArticleYears = data => request('MdmAdmin/KolService/SearchArticleYears', data);

// KOL医生详情
export const GetKolDetail = data => request('MdmAdmin/KolService/GetKolDetail', data);
