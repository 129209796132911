<!--  -->
<template>
  <div class="container">
    <p class="title">KOL360画像</p>
    <div class="selct">
      <div class="select-top">
        <el-select
          collapse-tags
          filterable
          clearable
          v-model="orgType"
          multiple
          placeholder="机构类型"
        >
          <el-option
            v-for="item in options.orgTypeOptions"
            :key="item.type_id"
            :label="item.type_name"
            :value="item.type_id"
          ></el-option>
        </el-select>
        <el-select
          collapse-tags
          filterable
          clearable
          class="interval"
          v-model="orgLevel"
          multiple
          placeholder="机构级别"
        >
          <el-option
            v-for="item in options.orgLeveltions"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-cascader
          :options="options.adressOptions"
          collapse-tags
          filterable
          clearable
          class="interval"
          placeholder="地理位置"
          :props="addressProps"
          v-model="adress"
        ></el-cascader>
      </div>
      <div class="select-bottom">
        <el-cascader
          :options="options.standardOptions"
          collapse-tags
          filterable
          clearable
          placeholder="所在科室"
          :props="standardDeptName"
          v-model="standard"
        ></el-cascader>
        <el-select
          collapse-tags
          filterable
          clearable
          class="interval"
          v-model="professionalTitle"
          multiple
          placeholder="专业职称"
        >
          <el-option
            v-for="item in options.professionalTitleOptins"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-select
          collapse-tags
          filterable
          clearable
          class="interval"
          v-model="academicTitle"
          multiple
          placeholder="学术头衔"
        >
          <el-option
            v-for="item in options.academicTitleOptions"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-select
          collapse-tags
          filterable
          clearable
          class="interval"
          v-model="administrativeDuties"
          multiple
          placeholder="行政职务"
        >
          <el-option
            v-for="item in options.administrativeDutiesOptions"
            :key="item.code"
            :label="item.label"
            :value="item.code"
          ></el-option>
        </el-select>
        <el-button type="primary" class="search" @click="handleSearchDataBtn">查询</el-button>
        <el-button class="reset" @click="clearSearchOption">重置</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" :header-cell-style="headercellstyle" v-loading="loading">
        <el-table-column label="医生姓名" width="200">
          <template slot-scope="scope">
            <span class="relation-hcpName" @click="handleToDetail(scope.row)">
              {{ scope.row.hcp_name }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="hcp_standard_dept_name" label="所属科室">
          <template slot-scope="scope">
            {{ scope.row.hcp_standard.join('/') }}
          </template>
        </el-table-column>
        <el-table-column prop="hco_name" label="所属机构"></el-table-column>
        <el-table-column prop="hcp_nhc_location" label="地理位置">
          <template slot-scope="scope">
            {{ `${scope.row.hco_province_name}/${scope.row.hco_city_name}` }}
          </template>
        </el-table-column>
        <el-table-column prop="hcp_professional_title" label="专业职称">
          <template slot-scope="scope">
            {{ showLabel(scope.row.hcp_professional_title, 'professionalTitleOptins') }}
          </template>
        </el-table-column>
        <el-table-column prop="hcp_academic_title" label="学术头衔">
          <template slot-scope="scope">
            {{ showLabel(scope.row.hcp_academic_title, 'academicTitleOptions') }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="60">
          <template slot-scope="scope">
            <el-button @click="handleToDetail(scope.row)" type="text" class="view">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="tableData.length === 0 && !loading" class="no-data">
      <img
        src="https://nges-dev-1251316161.cos.ap-guangzhou.myqcloud.com/nges/62102202/ab45409c-87af-4e99-908d-ac9943ece194"
        alt="未查询到相关数据"
      />
      <div class="no-data-title">当前行业总库已覆盖 460万 医疗专业人士信息</div>
      <div class="no-data-tip">
        您可以查询与您业务相关的专业人士数量，并查看部分样例数据，如需购买可联系：md@tencent.com
      </div>
    </div>

    <div class="pagination" v-if="tableData.length">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="page_size"
        :current-page="index"
        layout="total, prev, pager, next, sizes, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { GetDictOptionList, GetOrgTypeList } from '@/api/data';
import { GetAllProvinceCityInfo, GetAllDepInfo } from '@/api/trade-search';
import { SearchKolHcpList } from '@/api/doctor-image';
export default {
  name: 'DoctorImage',
  data() {
    return {
      // 地理位置
      addressProps: {
        multiple: true,
        value: 'code',
        label: 'name',
        checkStrictly: true,
      },
      //   科室
      standardDeptName: {
        multiple: true,
        value: 'code',
        label: 'name',
        checkStrictly: true,
      },
      options: {
        orgTypeOptions: [],
        orgLeveltions: [],
        professionalTitleOptins: [],
        academicTitleOptions: [],
        administrativeDutiesOptions: [],
        adressOptions: [],
        standardOptions: [],
      },
      orgType: [], // 机构类型
      orgLevel: [], // 机构级别
      adress: [], // 地理位置
      standard: [], // 所在科室
      professionalTitle: [], // 专业职称
      academicTitle: [], // 学术头衔
      administrativeDuties: [], // 行政职务
      tableData: [],
      headercellstyle: {
        height: '46px',
        background: '#F2F3F8',
      },
      loading: false,
      index: 1,
      page_size: 10,
      total: 0,
    };
  },
  created() {
    this.getOrgType();
    this.getOrgLevel();
    this.getProfessionalTitle();
    this.getAcademicTitle();
    this.getAdministrativeDuties();
    this.getAdress();
    this.getStandard();
  },
  mounted() {
    this.handleSearchData();
  },
  methods: {
    handleToDetail(row) {
      console.log(row);
      this.g_tourl(`/dashboard/doctor-image-detail?id=${row.hcp_id}&daas_code=${row.daas_code}`);
    },
    handleCurrentChange(val) {
      this.index = val;
      this.handleSearchData();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.handleSearchData();
    },
    handleSearchDataBtn() {
      this.index = 1;
      this.handleSearchData();
    },
    //   查询
    handleSearchData() {
      this.loading = true;
      const req = {
        search_fields: [
          'hcp_name',
          'hcp_standard_dept_name',
          'hco_name',
          'hcp_nhc_location',
          'hcp_professional_title',
          'hcp_academic_title',
          'hco_province_name',
          'hco_city_name',
        ],
        SearchOptions: [],
        index: this.index,
        page_size: this.page_size,
      };
      if (this.orgType.length) {
        const orgName = [];
        const orgValue = [];
        this.orgType.forEach(item => {
          if (item !== '') {
            orgName.push('hco_type2');
            orgValue.push(item);
          }
        });
        req.SearchOptions.push({
          field_name: orgName.join(','),
          field_value: orgValue.join(','),
        });
      }
      if (this.orgLevel.length) {
        const LevelName = [];
        const LevelValue = [];
        this.orgLevel.forEach(item => {
          if (item !== '') {
            LevelName.push('hco_hospital_level');
            LevelValue.push(item);
          }
        });
        req.SearchOptions.push({
          field_name: LevelName.join(','),
          field_value: LevelValue.join(','),
        });
      }

      if (this.adress.length) {
        const adressLevelName = [];
        const addressLevelValue = [];
        this.adress.forEach(item => {
          if (item.length === 1) {
            adressLevelName.push('hco_province_code');
            addressLevelValue.push(item[0]);
          }
          if (item.length === 2) {
            adressLevelName.push('hco_city_code');
            addressLevelValue.push(item[1]);
          }
        });
        req.SearchOptions.push({
          field_name: adressLevelName.join(','),
          field_value: addressLevelValue.join(','),
        });
      }

      if (this.standard.length) {
        const deptName = [];
        const deptValue = [];
        this.standard.forEach(item => {
          if (item.length === 1) {
            deptName.push('hcp_standard_dept1');
            deptValue.push(item[0]);
          }
          if (item.length === 2) {
            deptName.push('hcp_standard_dept2');
            deptValue.push(item[1]);
          }
          if (item.length === 3) {
            deptName.push('hcp_standard_dept3');
            deptValue.push(item[2]);
          }
        });
        req.SearchOptions.push({
          field_name: deptName.join(','),
          field_value: deptValue.join(','),
        });
      }

      if (this.professionalTitle.length) {
        const professionalName = [];
        const professionalValue = [];
        this.professionalTitle.forEach(item => {
          if (item !== '') {
            professionalName.push('hcp_professional_title');
            professionalValue.push(item);
          }
        });
        req.SearchOptions.push({
          field_name: professionalName.join(','),
          field_value: professionalValue.join(','),
        });
      }
      if (this.academicTitle.length) {
        const academicName = [];
        const academicValue = [];
        this.academicTitle.forEach(item => {
          if (item !== '') {
            academicName.push('hcp_academic_title');
            academicValue.push(item);
          }
        });
        req.SearchOptions.push({
          field_name: academicName.join(','),
          field_value: academicValue.join(','),
        });
      }
      if (this.administrativeDuties.length) {
        const administrativeName = [];
        const administrativeValue = [];
        this.administrativeDuties.forEach(item => {
          if (item !== '') {
            administrativeName.push('hcp_administrative_title');
            administrativeValue.push(item);
          }
        });
        req.SearchOptions.push({
          field_name: administrativeName.join(','),
          field_value: administrativeValue.join(','),
        });
      }
      SearchKolHcpList(req)
        .then(res => {
          let dep = [];
          dep = res.data.map(item => {
            return { ...JSON.parse(item) };
          });
          this.tableData = dep.map(item => {
            return {
              ...item,
              hcp_standard: [
                item.hcp_standard_dept_name1,
                item.hcp_standard_dept_name2,
                item.hcp_standard_dept_name3,
              ].filter(item => item !== ''),
            };
          });
          console.log('格式化后的数据', this.tableData);
          this.total = res.total_num;
          this.loading = false;
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    //   重置
    clearSearchOption() {
      this.orgType = [];
      this.orgLevel = [];
      this.adress = [];
      this.standard = [];
      this.professionalTitle = [];
      this.academicTitle = [];
      this.administrativeDuties = [];
      this.index = 1;
    },
    // 获取科室
    getStandard() {
      GetAllDepInfo().then(res => {
        this.options.standardOptions = res.datas;
        this.options.standardOptions = this.formatData(this.options.standardOptions);
      });
    },
    // 过滤掉空的children
    formatData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children == null || data[i].children.length === 0) {
          data[i].children = undefined;
        } else if (data[i].children) {
          this.formatData(data[i].children);
        }
      }
      return data;
    },
    // 获取地理位置
    getAdress() {
      GetAllProvinceCityInfo().then(res => {
        this.options.adressOptions = res.datas;
      });
    },
    // 获取机构类型
    getOrgType() {
      GetOrgTypeList({ level: 2 }).then(res => {
        this.options.orgTypeOptions = res.datas;
      });
    },
    // 获取机构级别
    getOrgLevel() {
      GetDictOptionList({ dict_api_name: 'hospital_level', dict_type: '0' }).then(res => {
        this.options.orgLeveltions = res.dict_list;
      });
    },
    // 获取专业职称
    getProfessionalTitle() {
      GetDictOptionList({ dict_api_name: 'professional_title', dict_type: '1' }).then(res => {
        this.options.professionalTitleOptins = res.dict_list;
      });
    },
    // 获取学士头衔
    getAcademicTitle() {
      GetDictOptionList({ dict_api_name: 'academic_title', dict_type: '1' }).then(res => {
        this.options.academicTitleOptions = res.dict_list;
      });
    },
    // 获取行政职务
    getAdministrativeDuties() {
      GetDictOptionList({ dict_api_name: 'administrative_title ', dict_type: '1' }).then(res => {
        this.options.administrativeDutiesOptions = res.dict_list;
      });
    },
    showLabel(code, list) {
      const item = this.options[list].filter(item => item.code === code);
      if (item.length) return item[0].label;
      return '-';
    },
  },
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
